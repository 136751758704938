import { Check } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  Stack,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import api from "../../../apis";
import SubmitBtn from "../../SubmitBtn";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";

export default function CaseAssignInvestigatorForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  isTpo = false,
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  handleCancel,
  status,
}) {
  const assign_case = useSelector(
    (state) => state.auth.user.permissions.assign_case ?? []
  );
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [userOptions, setUserOptions] = React.useState([]);

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  const checkuserId = useCallback(async (userId) => {
    try {
      const res = await api.get(`/api/assign/user?org_code=${userId}`);

      let data = res.data.map((item) => {
        return {
          value: item.id ?? "",
          label: `${item.rank} ${item.fname} ${item.lname}`,
        };
      });

      setUserOptions(data);
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  useEffect(() => {
    if (values?.to_org_code) {
      checkuserId(values?.to_org_code);
    }
  }, [checkuserId, values?.to_org_code]);

  const {
    isLoading: ispolicestationOptionsLoading,
    data: policestationOptions,
    error: policestationOptionsError,
  } = useQuery(
    [
      "policestation",
      "/api/assign/policestation",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/assign/policestation").then((res) => {
        return res?.data?.map((row) => ({
          value: row.ORG_CODE,
          label: row.ORG_ABBR,
        }));
      }),
    { staleTime: 3 * 60 * 1000 }
  );

  return (
    <Paper
      sx={{
        px: 3,
        pb: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <form onSubmit={handleSubmit} noValidate>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" color="warning.main">
            Warning
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error || submitError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>ปิด</Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Divider textAlign="center">มอบหมายพนักงานสอบสวน</Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAutoComplete
              name="to_org_code"
              label="หน่วยงานที่ต้องการมอบหมาย"
              required
              controlProp={{ fullWidth: true }}
              inputProps={{
                sx: { width: "100%" },
                loading:
                  ispolicestationOptionsLoading || policestationOptionsError,
                loadingText: policestationOptionsError ? "Error" : "Loading…",
              }}
              options={policestationOptions}
              readOnly={status === "assigned"}
              disabled={status === "assigned"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAutoComplete
              name="user_id"
              label="ชื่อพนักงานสอบสวนที่ต้องการมอบหมาย"
              options={userOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              readOnly={status === "assigned"}
              disabled={status === "assigned"}
              // inputProps={{ sx: { fullWidth: true } }}
              // controlProp={{ sx: { fullWidth: true } }}
            />

            <FormHelperText id="user_id-helper" variant="outlined">
              กรุณาระบุหน่วยงานที่ต้องการมอบหมายก่อน
            </FormHelperText>
          </Grid>

          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item md={12} lg={4}>
            {assign_case.includes("assign") && (
              <SubmitBtn
                variant="contained"
                startIcon={<Check />}
                submitting={submitting}
                pristine={pristine}
                {...(status === "assigned" ? { disabled: true } : {})}
              >
                อนุมัติการมอบหมาย
              </SubmitBtn>
            )}
          </Grid>
          <Grid item md={12} lg={8}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  handleCancel();
                }}
                disabled={false}
              >
                ปิด
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export const validateRules = () => ({
  to_org_code: Joi.any()
    .messages({
      "string.base": `กรุณาระบุหน่วยงานที่ต้องการมอบหมาย`,
      "any.required": "กรุณาระบุหน่วยงานที่ต้องการมอบหมาย",
    })
    .required(),
  user_id: Joi.any().messages({
    "any.only": "กรุณาระบุหน่วยงานที่ต้องการมอบหมายก่อน",
    "any.required": "กรุณาระบุชื่อพนักงานสอบสวนที่ต้องการมอบหมาย",
  }),
});

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema().validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

const schema = () => Joi.object(validateRules());
