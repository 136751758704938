import { Mail } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import { telcoOptionsQuery } from "../../../../data/queries/telco";
import bankAccountTypes from "../../../../data/selects/bankAccountTypes";
import { getTextMask } from "../../../../helpers/imask";
import validationMsg from "../../../../helpers/validationMsg";
import FieldAsyncAutoComplete from "../../../form-fields/FieldAsyncAutoComplete";
import FieldCheckBox from "../../../form-fields/FieldCheckBox";
import FieldDatePicker from "../../../form-fields/FieldDatePicker";
import FieldInput from "../../../form-fields/FieldInput";
import FieldSelect from "../../../form-fields/FieldSelect";
import FieldsignatureFileDropZone from "../../../form-fields/FieldsignatureFileDropZone";
import SubmitBtn from "../../../SubmitBtn";
import SignaturePreview from "../../requests/request-forms/SignaturePreview";

export default function RequestTelcoForm2({
  readOnly = false,
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  modifiedSinceLastSubmit,
  form,
  values,
}) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const navigate = useNavigate();
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedTelephone, setSelectedTelephone] = useState(null);

  const {
    data: rawProviderOptions,
    // error: telcoOptionsError,
  } = useQuery(telcoOptionsQuery());

  // error & warning dialog state
  const [errorDialogState, setErrorDialogState] = useState(false);
  const handleErrorDialogClose = () => setErrorDialogState(false);
  // snackbar state
  const [toastState, setToastState] = useState(false);
  const [toastData, setToastData] = useState({});
  const handleToastClose = () => {
    setToastState(false);
    setToastData({});
  };

  const [telephones, setTelephones] = useState([]);

  const providerOptions = useMemo(() => {
    const items =
      rawProviderOptions &&
      rawProviderOptions.filter((row) =>
        telephones.some((el) => el.provider_id === row.value)
      );
    return _.uniqBy(items, "value");
  }, [rawProviderOptions, telephones]);

  const telephoneOptions =
    telephones.length > 0
      ? telephones
          .filter((el) => el.provider_id === values.provider)
          .map((tel) => {
            const find = _.find(
              bankAccountTypes,
              (o) => o.value === tel.type_id
            );
            return {
              label: `${tel.provider}: ${tel.phone_number} (${find.label})`,
              value: tel.phone_number,
            };
          })
      : [];

  // handle functions
  const handleTelephoneChange = (value) => {
    setSelectedTelephone(value);
  };

  // const handleCheckboxChange = (e, setTelco) => {
  //   const { value, checked } = e.target;
  //   if (checked) {
  //     setTelco(true);
  //     addRequestItem({ id: value });
  //   } else {
  //     setTelco(false);
  //     removeRequestItem({ id: value });
  //   }
  // };

  // const [selectedTelcos, setSelectedTelcos] = useState([]);
  // const addRequestItem = (item) => {
  //   setSelectedTelcos((prevItems) => [...prevItems, item]);
  // };
  // const removeRequestItem = (item) => {
  //   setSelectedTelcos((prevItems) => prevItems.filter((i) => i.id !== item.id));
  // };
  // const isSelectedRequest = (item) => {
  //   const find = _.find(selectedTelcos, (o) => {
  //     return parseInt(o.id) === item;
  //   });
  //   const result = find ? true : false;
  //   return result;
  // };
  const renderForm = (short_name) => {
    const form_file = `form_${short_name
      .toString()
      .toLowerCase()
      .replaceAll(" ", "_")}`;

    const json = require(`../../../../data/json/${form_file}.json`);
    // setFormFields(json);
    return json.length > 0 ? (
      <>
        {json.map((field, index) => {
          switch (field.type) {
            case "input":
              field.name === "phone_number" &&
                form.change(short_name + "." + field.name, selectedTelephone);
              return (
                <Grid item xs={12} lg={field.lg} key={index}>
                  <FieldInput
                    name={short_name + "." + field.name}
                    label={field.label}
                    required={field.required}
                    controlProps={field.controlProps}
                    readOnly={field.readOnly}
                    inputProps={{
                      ...field.inputProps,
                      startAdornment: field.inputProps.startAdornment && (
                        <InputAdornment position="start">
                          {field.inputProps.startAdornment}
                        </InputAdornment>
                      ),
                      inputComponent:
                        field.inputProps.inputComponent === "phoneNumberMask"
                          ? phoneNumberMask
                          : undefined,
                    }}
                  />
                </Grid>
              );
            case "date":
              return (
                <Grid item xs={12} lg={field.lg} key={index}>
                  <FieldDatePicker
                    name={short_name + "." + field.name}
                    label={field.label}
                    required={field.required}
                    pickerProps={field.pickerProps}
                  />
                </Grid>
              );
            default:
              return null;
          }
        })}
      </>
    ) : null;
  };

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setErrorDialogState(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  useEffect(() => {
    api.get(`/api/cases/${caseId}/telephones`).then((response) => {
      setTelephones(response.data.rows);
    });
    api
      .get(`/api/request/types?object_type=telco&status=1`)
      .then(({ data }) => {
        setRequestTypes(data);
      });
  }, [caseId]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={errorDialogState}
        onClose={handleErrorDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogClose}>ปิด</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toastState ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastData?.type && (
          <Alert
            onClose={handleToastClose}
            severity={toastData?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastData?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Grid container spacing={2} justifyContent="center">
        {submitFailed && (error || submitError) && (
          <Grid item md={12}>
            <Alert severity="warning" sx={{ width: "100%" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack spacing={2} direction="row">
            <FieldSelect
              name="provider"
              label="ผู้ให้บริการ"
              options={providerOptions}
              readOnly={readOnly}
              required
              controlProp={{ sx: { width: "50%" } }}
            />
            <FieldSelect
              name="telco"
              label="หมายเลขโทรศัพท์"
              options={telephoneOptions}
              readOnly={readOnly}
              required
              controlProp={{ sx: { width: "50%" } }}
              onChange={handleTelephoneChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="center"></Divider>
        </Grid>
      </Grid>
      <Grid item ml={2} mt={2} justifyContent="rigth">
        <Grid item xs={12}>
          <Typography variant="subtitle1">เลือกประเภทคำขอ</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          {requestTypes.map((v, i) => {
            return (
              <>
                <FieldCheckBox
                  name={"request_type_" + v.value}
                  value={v.value}
                  label={v.label}
                  readOnly={!selectedTelephone}
                />
                <Grid container spacing={2} justifyContent="center">
                  {values["request_type_" + v.value] &&
                    renderForm(v.short_name)}
                </Grid>
              </>
            );
          })}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider textAlign="center" sx={{ mt: 2 }}>
              ข้อมูลพนักงานสอบสวน
            </Divider>
          </Grid>
          <Grid item xs={12} md={2}>
            <FieldInput
              name="rank"
              label="ยศ"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <FieldInput
              name="fname"
              label="ชื่อ"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <FieldInput
              name="lname"
              label="สกุล"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="job_title"
              label="ตำแหน่ง"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAsyncAutoComplete
              name="policestation_org_code"
              label="หน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              readOnly
              basePath="/api/policestations"
              requestParams={{ page_size: undefined, page: undefined }}
              // itemFilter={(row) =>
              //   !row.ORG_CODE?.startsWith("0") && !row.ORG_CODE?.startsWith("1")
              // }
              itemFormat={(row) => {
                return { value: row.ORG_CODE, label: row.ORG_ABBR };
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="email"
              label="email"
              required
              controlProps={{ fullWidth: true }}
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="pol_tel"
              label="โทรศัพท์"
              required
              controlProps={{ fullWidth: true }}
              readOnly
            />
          </Grid>
          <Grid item xs={12}>
            <FieldInput
              name="address"
              label="ที่อยู่"
              required
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
            />
          </Grid>
          <Grid container item xs={12} justifyContent={"center"}>
            <Grid item xs={12} lg={6}>
              <Typography variant="subtitle1">ลายเซ็น</Typography>
              {readOnly ? (
                <SignaturePreview name="profile_signature" />
              ) : (
                <FieldsignatureFileDropZone
                  name="profile_signature"
                  accept="image/jpeg, image/png"
                  placeholder={
                    <>
                      Drag 'n' drop some files here, or click to select files{" "}
                      <br />
                      (*.jpg,*.gif,*.png) only
                    </>
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} margin={3}>
          <Divider textAlign="center"></Divider>
        </Grid>
        <Grid item md={12} sx={{ mt: 2 }}>
          <Stack direction="row" spacing={2}>
            {!readOnly && (
              <SubmitBtn
                variant="contained"
                startIcon={<Mail />}
                // submitting={submitting}
                // pristine={pristine}
              >
                สร้างคำขอ
              </SubmitBtn>
            )}
            <Button
              type="button"
              variant="outlined"
              disabled={submitting}
              onClick={() => navigate(`/cases/${caseId}/mgt`)}
            >
              {readOnly ? "ปิด" : "ยกเลิก"}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

export const mapToInit = (item) => {
  return _.pick(item, Object.keys(validateRules));
};

const phoneNumberMask = getTextMask({
  // mask: "0000aaa[aa]00000",
  mask: /^0\d{0,9}$/,
  unmask: true,
  overwrite: true,
});

export const validateRules = {
  provider: Joi.number().required(),
  telco: Joi.string().required(),
  request_type_9: Joi.boolean().allow(null),
  request_type_10: Joi.boolean().allow(null),
  Telco_A: Joi.when("request_type_9", {
    is: Joi.valid(true).required(),
    then: Joi.object({
      phone_number: Joi.string().required(),
      no: Joi.string().required(),
      date: Joi.string().required(),
    }),
    otherwise: Joi.object(),
  }),
  Telco_B: Joi.when("request_type_10", {
    is: Joi.valid(true).required(),
    then: Joi.object({
      phone_number: Joi.string().required(),
      from_date: Joi.string().required(),
      to_date: Joi.string().required(),
      no: Joi.string().required(),
      date: Joi.string().required(),
    }),
    otherwise: Joi.object(),
  }),
  rank: Joi.string().max(255).required(),
  fname: Joi.string().max(255).required(),
  lname: Joi.string().max(255).required(),
  job_title: Joi.string().max(255).required(),
  email: Joi.string().required(),
  policestation_org_code: Joi.string()
    .pattern(/^\d{5}$/)
    .required(),
  pol_tel: Joi.string()
    .pattern(/^\d{10}$/)
    .required(),
  address: Joi.string().required(),
  profile_signature: Joi.any()
    .messages({
      "string.empty": "กรุณาอัพโหลดลายเซ็น",
      "any.required": "กรุณาอัพโหลดลายเซ็น",
    })
    .required(),
};

const schema = Joi.object(validateRules).error(validationMsg());

export const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  return errors;
};
