import api from "../apis";
import _ from "lodash";

export const CASE_ASSIGN_FETCH_LIST = "caseAssign/CASE_ASSIGN_FETCH_LIST";
export const CASE_ASSIGN_FETCH_ONE = "caseAssign/CASE_ASSIGN_FETCH_ONE";
export const CASE_ASSIGN_CREATE = "caseAssign/CASE_ASSIGN_CREATE";
export const CASE_ASSIGN_DELETE = "caseAssign/CASE_ASSIGN_DELETE";
export const CASE_ASSIGN_EDIT = "caseAssign/CASE_ASSIGN_EDIT";
export const CASE_ASSIGN_CLEAR_RESULT = "caseAssign/CASE_ASSIGN_CLEAR_RESULT";
export const CASE_ASSIGN_MARK_LOADING = "caseAssign/CASE_ASSIGN_MARK_LOADING";
export const CASE_ASSIGN_LOADING = "caseAssign/CASE_ASSIGN_LOADING";
export const CASE_ASSIGN_SET_FILTER = "caseAssign/CASE_ASSIGN_SET_FILTER";
export const CASE_ASSIGN_FORCE_RELOAD = "caseAssign/CASE_ASSIGN_FORCE_RELOAD";
export const CASE_ASSIGN_CHANGE_PAGE = "caseAssign/CASE_ASSIGN_CHANGE_PAGE";
export const CASE_ASSIGN_CHANGE_PAGE_SIZE =
  "caseAssign/CASE_ASSIGN_CHANGE_PAGE_SIZE";

const defaultState = {
  data: [],
  meta: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case CASE_ASSIGN_FETCH_LIST:
      return {
        ...state,
        data: action.payload,
        meta: {
          ...state.meta,
        },
      };
    case CASE_ASSIGN_FETCH_ONE:
    case CASE_ASSIGN_CREATE:
    case CASE_ASSIGN_DELETE:
    case CASE_ASSIGN_EDIT:
    case CASE_ASSIGN_CLEAR_RESULT:
    case CASE_ASSIGN_MARK_LOADING:
    case CASE_ASSIGN_LOADING:
      return {
        ...state,
        meta: {
          loading: action.payload,
          ...state.meta,
        },
      };
    case CASE_ASSIGN_SET_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filter: action.payload,
          page: 0,
        },
      };
    case CASE_ASSIGN_CHANGE_PAGE:
    case CASE_ASSIGN_CHANGE_PAGE_SIZE:
    default:
      return state;
  }
}

export const caseAssignFetchList =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch({
      type: CASE_ASSIGN_LOADING,
      payload: true,
    });

    try {
      const response = await api.get("/api/assign", {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });

      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: CASE_ASSIGN_FETCH_LIST,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(caseAssignClearResult());
      throw e;
    } finally {
      dispatch({
        type: CASE_ASSIGN_LOADING,
        payload: false,
      });
    }
  };

export const caseAssignChangePage = (page) => {
  return { type: CASE_ASSIGN_CHANGE_PAGE, payload: page };
};
export const caseAssignChangePageSize = (pageSize) => {
  return { type: CASE_ASSIGN_CHANGE_PAGE_SIZE, payload: pageSize };
};
export const caseAssignClearResult = () => {
  return { type: CASE_ASSIGN_CLEAR_RESULT };
};

export const caseAssignSetFilter = (filter) => {
  return { type: CASE_ASSIGN_SET_FILTER, payload: filter };
};

export const caseAssignForceReload = () => {
  return { type: CASE_ASSIGN_FORCE_RELOAD };
};

export const casesAssign = (formsValues) => async (dispatch) => {
  await api.post("/api/assign", formsValues);
};

export const casesMultiAssign = (formsValues) => async (dispatch) => {
  await api.post("/api/multiple/assign", formsValues);
};
