import {
  Alert,
  AlertTitle,
  Chip,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React from "react";
import { Form } from "react-final-form";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import api from "../../../apis";
import { casesMultiAssign } from "../../../ducks/caseAssign";
import CaseAssignInvestigatorForm, {
  validate,
} from "./CaseAssignInvestigatorForm";

const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: "100%",
};

export default function CaseAssignMultiView({
  itemIds = [],
  handleCancel,
  status,
  setReload,
}) {
  const {
    isLoading,
    isError,
    data: item,
  } = useQuery(
    ["assign", itemIds],
    () =>
      api.get(`/api/assign/${itemIds[0]}`).then((res) => {
        if (status === "assigned") {
          return res.data;
        } else {
          return {
            ...res.data,
            police_name: `${res?.data?.last_assign?.user?.rank ?? ""} ${
              res?.data?.last_assign?.user?.fname ?? ""
            } ${res?.data?.last_assign?.user?.lname ?? ""}`,
            job_title: res?.data?.last_assign?.user?.job_title,
            policestation_org_code:
              res?.data?.last_assign?.current_org?.ORG_CODE,
          };
        }
      }),
    { staleTime: 60 * 1000 }
  );
  const dispatch = useDispatch();
  const onSubmit = async (formValues, form) => {
    const objData = {
      case_id: itemIds,
      user_id: formValues.user_id,
      to_org_code: formValues.to_org_code,
    };

    return dispatch(casesMultiAssign(objData))
      .then(() => {
        handleCancel();
        form.restart();
        setReload((prev) => prev + 1);
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  const getInitialValues = () => {
    if (status === "assigned") {
      return {
        to_org_code: item?.policestation_org_code,
        user_id: item?.user_id,
      };
    }
    return {};
  };

  const renderContent = () => {
    if (isLoading)
      return (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      );

    if (isError)
      return (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง
        </Alert>
      );

    return (
      <>
        <Form
          status={status}
          initialValues={getInitialValues()}
          validate={validate}
          onSubmit={onSubmit}
          component={CaseAssignInvestigatorForm}
          handleCancel={handleCancel}
          editMode
        />
      </>
    );
  };

  const modalStyle = {
    ...masterModalStyle,
    height: item ? "auto" : undefined,
    width: item ? "80%" : undefined,
  };

  return (
    <Box sx={modalStyle}>
      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <Typography id="modal-modal-title" variant="h6" component="div">
          ตรวจสอบรายการมอบหมมอบหมายเคสจำนวนทั้งหมด {itemIds.length} เคส
        </Typography>
        {status === "assigned" ? (
          <Chip
            label="มอบหมายแล้ว"
            color="success"
            sx={{
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                fontFamily: "Kanit",
              },
            }}
          />
        ) : (
          <Chip
            label="รอมอบหมาย"
            color="info"
            sx={{
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                fontFamily: "Kanit",
              },
            }}
          />
        )}
      </Stack>

      {renderContent()}
    </Box>
  );
}
